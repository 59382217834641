<template>
	<div class="error">
		<picture class="error__background">
            <source media="(min-width:1440px)" :srcset="require('@/assets/404_1440.png')">
            <source media="(min-width:992px)" :srcset="require('@/assets/404_992.png')">
            <source media="(min-width:768px)" :srcset="require('@/assets/404_768.png')">
            <source media="(min-width:320px)" :srcset="require('@/assets/404_320.png')">
            <img :src="require('@/assets/404_1440.png')" alt="Easy Dating">
        </picture>

		<div class="error__wrapper">
			<div class="error__title">
				<img :src="require('@/assets/404.svg')" alt="404">
			</div>
			<div class="error__caption">Page not found...</div>

			<router-link to="/" class="button">
				Back to home page
			</router-link>
		</div>

		<div class="error__text">Don't  worry!</div>
	</div>
</template>

<script>
export default {
	name: 'page-error'
};
</script>

<style lang="scss">
	.error {
		position: relative;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		flex-direction: column;
		width: 100%;
		height: 100vh;
		min-height: fit-content;
		padding: 0 30px;
		text-align: center;
		overflow: hidden;

		@include md {
			align-items: flex-start;
			justify-content: space-between;
		}

		@include lg {
			align-items: flex-start;
			justify-content: space-between;
		}

		&__text {
			z-index: 2;
			position: relative;
			font-weight: 700;
			text-transform: uppercase;
			white-space: nowrap;
			text-align: left;

			@include xs {
				font-size: 43px;
				line-height: 54px;
				text-align: center;
				margin-bottom: auto;
				margin-top: auto;
			}

			@include sm {
				margin: auto 30px auto auto;
				padding: 50px 0;
				font-size: 61px;
				line-height: 77px;
			}

			@include md {
				margin: auto 60px auto auto;
				padding: 50px 0;
				font-size: 81px;
				line-height: 102px;
			}

			@include lg {
				margin: auto 80px auto auto;
				padding: 50px 0;
				font-size: 100px;
				line-height: 126px;
			}
		}

		&__background {
			z-index: -1;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: left top;
			}
		}

		&__title {
			z-index: 2;
			position: relative;
			width: 100%;

			@include xs {
				margin-top: 60px;
			}

			@include sm {
				max-width: 503px;
				margin: 52px auto 0 auto;
			}

			@include md {
				margin: 90px auto 0 5px;
			}

			@include lg {
				font-size: 212px;
				line-height: 352px;
				margin-bottom: -115px;
			}
		}

		&__caption {
			z-index: 2;
			position: relative;
			line-height: 1;
			font-weight: 600;
			font-size: 30px;
			line-height: 38px;

			@include xs {
				margin: 40px 0 20px 0;
			}

			@include sm {
				margin: 46px 0 22px 0;
			}

			@include md {
				margin: 70px 0 22px 0;
			}

			@include lg {
				margin: 80px 0 22px 0;
			}
		}

		&__wrapper {
			position: relative;
			width: 100%;

			@include xs {
				padding: 0 0 80px 0;
			}

			@include sm {
				padding: 0 0 86px 0;
			}

			@include md {
				max-width: 503px;
			}

			@include lg {
				max-width: 536px;
			}
		}

		.button {
			display: block;
			width: 267px;
			height: 51px;
			margin: 0 auto;
		}
	}
</style>
